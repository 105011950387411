import IconDropbox from "assets/images/icon_dropbox.svg";
import IconComplete from "assets/images/icon-complete.svg";
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from "react";

import styles from "./combobox.module.scss";

interface ComboboxProps {
  id: string;
  label: string;
  chosenOption?: string;
  chosenIndex?: number;
  options: Array<any>;
  onSelect?: (
    childId: string | undefined,
    parentId: string | undefined
  ) => void;
  onChange?: (value: string) => void;
  readOnlyInput?: boolean;
  placeholder?: string;
}

export const Combobox: FunctionComponent<ComboboxProps> = ({
  id,
  label,
  chosenOption,
  chosenIndex,
  options,
  onSelect,
  onChange,
  readOnlyInput = false,
  placeholder
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedOption, setSelectedOption] = useState(chosenOption ?? null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState(
    chosenIndex ? chosenIndex : -1
  );

  const comboboxRef = useRef(null);
  const inputRef = useRef(null);

  // Handle closing dropdown, keydown and scrolling
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (document.activeElement === inputRef.current) {
        if (event.key === "ArrowDown") {
          event.preventDefault();
          if (isOpen) {
            setHighlightedIndex((prevIndex) =>
              prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex
            );
            handleScroll("down");
          } else {
            toggleDropdown();
          }
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          if (isOpen) {
            setHighlightedIndex((prevIndex) =>
              prevIndex > 0 ? prevIndex - 1 : prevIndex
            );
            handleScroll("up");
          } else {
            toggleDropdown();
          }
        } else if (event.key === "Enter") {
          if (!isOpen) {
            toggleDropdown();
          }
          if (isOpen && highlightedIndex !== -1) {
            handleOptionClick(filteredOptions[highlightedIndex]);
          }
        }
      }
    };

    const handleScroll = (direction: "up" | "down") => {
      const dropdown = document.querySelector(
        `.${styles["combobox-dropdown"]}`
      );
      const itemHeight = document.querySelector(
        `.${styles["combobox-item"]}`
      )?.clientHeight;

      if (dropdown) {
        if (direction === "up" && highlightedIndex > 0) {
          dropdown.scrollTop = (highlightedIndex - 1) * itemHeight;
        } else if (
          direction === "down" &&
          highlightedIndex < filteredOptions.length - 1
        ) {
          dropdown.scrollTop = (highlightedIndex + 1) * itemHeight;
        }
      }
    };

    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("keydown", handleEscKey);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("keydown", handleEscKey);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [comboboxRef, isOpen, filteredOptions, highlightedIndex]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value !== "") {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      if (onSelect) {
        onSelect(undefined, undefined);
      }
    }

    if (selectedOption !== null) {
      setSelectedOption(null);
    }

    const filtered = options.filter((option) =>
      option.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setHighlightedIndex(-1);
  };

  const handleOptionClick = (option: any) => {
    setSelectedOption(option.title);
    clearSearch();
    setIsOpen(false);
    if (onSelect) {
      onSelect(option.id, option.parentGroupId);
    }
    if (onChange) {
      onChange(option.id);
    }
  };

  const handleOptionHover = (index: number) => {
    setHighlightedIndex(index);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      // If opening the dropdown, scroll to the selected option
      setTimeout(() => {
        scrollSelectedOptionIntoView();
      }, 100);
    }
  };

  const scrollSelectedOptionIntoView = () => {
    const selectedOptionElement = document.querySelector(
      "[aria-selected='true']"
    ) as HTMLElement;

    if (selectedOptionElement) {
      selectedOptionElement.scrollIntoView({ block: "nearest" });
    }
  };

  const clearSearch = () => {
    setSearchTerm("");
    setFilteredOptions(options);
  };

  return (
    <div ref={comboboxRef} className={styles["combobox"]}>
      <label htmlFor={id}>{label}</label>
      <div className={styles["combobox-wrapper"]}>
        <input
          type="text"
          autoComplete="off"
          name={id}
          id={id}
          ref={inputRef}
          value={selectedOption !== null ? selectedOption : searchTerm}
          onChange={handleInputChange}
          onClick={toggleDropdown}
          onFocus={(e) => e.target.select()}
          readOnly={readOnlyInput}
          placeholder={placeholder}
        />
        <div className={styles["combobox-button"]} onClick={toggleDropdown}>
          <span className={styles["sr-only"]}>Alternatives</span>
          <IconDropbox />
        </div>
      </div>

      {filteredOptions && filteredOptions.length > 0 && isOpen && (
        <ul className={styles["combobox-dropdown"]}>
          {filteredOptions.map((option, index) => (
            <li
              key={option.id}
              className={`${styles["combobox-item"]} ${
                index === highlightedIndex ? styles["highlighted"] : ""
              }`}
              role="option"
              aria-selected={option.title === selectedOption}
              onClick={() => handleOptionClick(option)}
              onMouseEnter={() => handleOptionHover(index)}
            >
              <span>{option.title}</span>
              {option.title === selectedOption && <IconComplete />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Combobox;
