/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line no-unused-vars
import classNames from "classnames";
import React, { ReactNode } from "react";

import styles from "./text.module.scss";

interface Props {
  size?:
    | "xxsmall"
    | "xsmall"
    | "small"
    | "medium"
    | "large"
    | "xlarge"
    | "xxlarge";
  children?: ReactNode;
  style?: React.CSSProperties;
  customClassName?: string;
  /**
   * Right-to-left text direction
   */
  rtl?: boolean;
  forceDangerouslySetInnerHTML?: boolean;
}

/**
 * Support for forwarding refs for this polymorphic component is based on this:
 * https://www.benmvp.com/blog/forwarding-refs-polymorphic-react-component-typescript/
 */

// Source: https://github.com/emotion-js/emotion/blob/master/packages/styled-base/types/helper.d.ts
// A more precise version of just React.ComponentPropsWithoutRef on its own
export type PropsOf<
  C extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
> = JSX.LibraryManagedAttributes<C, React.ComponentPropsWithoutRef<C>>;

type AsProp<C extends React.ElementType> = {
  /**
   * An override of the default HTML tag.
   * Can also be another React component.
   */
  as?: C;
};

/**
 * Allows for extending a set of props (`ExtendedProps`) by an overriding set of props
 * (`OverrideProps`), ensuring that any duplicates are overridden by the overriding
 * set of props.
 */
export type ExtendableProps<
  ExtendedProps = {},
  OverrideProps = {}
> = OverrideProps & Omit<ExtendedProps, keyof OverrideProps>;

/**
 * Allows for inheriting the props from the specified element type so that
 * props like children, className & style work, as well as element-specific
 * attributes like aria roles. The component (`C`) must be passed in.
 */
export type InheritableElementProps<
  C extends React.ElementType,
  Props = {}
> = ExtendableProps<PropsOf<C>, Props>;

/**
 * A more sophisticated version of `InheritableElementProps` where
 * the passed in `as` prop will determine which props can be included
 */
export type PolymorphicComponentProps<
  C extends React.ElementType,
  Props = {}
> = InheritableElementProps<C, Props & AsProp<C>>;

/**
 * Utility type to extract the `ref` prop from a polymorphic component
 */
export type PolymorphicRef<C extends React.ElementType> =
  React.ComponentPropsWithRef<C>["ref"];

/**
 * A wrapper of `PolymorphicComponentProps` that also includes the `ref`
 * prop for the polymorphic component
 */
export type PolymorphicComponentPropsWithRef<
  C extends React.ElementType,
  Props = {}
> = PolymorphicComponentProps<C, Props> & { ref?: PolymorphicRef<C> };

/**
 * The updated component props using PolymorphicComponentPropWithRef
 */
type TextProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<
  C,
  Props
>;

/**
 * The type used in the type annotation for the component
 */
type TextComponent = <C extends React.ElementType = "span">(
  props: TextProps<C>
) => React.ReactElement | null;

export const Text: TextComponent = React.forwardRef(
  <C extends React.ElementType = "span">(
    {
      as,
      size,
      children,
      style,
      customClassName,
      rtl,
      forceDangerouslySetInnerHTML = false
    }: TextProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const TextElement = as || "span";
    const className = classNames(styles["txt"], customClassName, {
      [styles["xxsmall"]]: size === "xxsmall",
      [styles["xsmall"]]: size === "xsmall",
      [styles["small"]]: size === "small",
      [styles["medium"]]: size === "medium",
      [styles["large"]]: size === "large",
      [styles["xlarge"]]: size === "xlarge",
      [styles["xxlarge"]]: size === "xxlarge",
      [styles["rtl"]]: rtl === true
    });

    /**
     * Applies dangerouslySetInnerHTML if raw string contains &shy;, else keeps children intact.
     * @returns React DOM-element.
     */
    const checkShy = () => {
      let foundDangerously = false;
      const shyString = children?.toString() ?? "";

      if (shyString.indexOf("&shy;") > 0 && forceDangerouslySetInnerHTML) {
        foundDangerously = true;
      }

      //prop override?
      if (forceDangerouslySetInnerHTML === true) foundDangerously = true;

      if (foundDangerously === true) {
        return (
          <TextElement
            className={className}
            style={style}
            ref={ref}
            dangerouslySetInnerHTML={{ __html: shyString }}
          ></TextElement>
        );
      } else {
        return (
          <TextElement className={className} style={style} ref={ref}>
            {children}
          </TextElement>
        );
      }
    };

    return checkShy();
  }
);

export default Text;
